.main {
    
    .header {
        width: 100%;

        z-index: 2;
        position: fixed;
        top: 0;

        pointer-events: none;
        user-select: none;

        .header-circle {
            position: absolute;
            right: 0;
            top: 0;
        }

        .header-container {
            max-width: 97%;
            width: 100%;

            margin: 0 auto;
            margin-top: 20px;

            display: flex;
            // align-items: center;
            justify-content: space-between;

            .header-left {

                .header-logo { 
                    max-width: 160px;
                    width: 100%;

                    cursor: pointer;
                    
                    .header-logo-top {
                        // margin-bottom: 6px;

                        img {
                            width: 160px;
                            pointer-events: all;
                        }

                    }

                    .header-logo-bottom {
                        font-size: 13px;
                        font-weight: 700;
                        text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.08);

                        color: #FFFFFF;
                            
                        pointer-events: all;
                    }
                }
            }

            .header-right {
                max-width: 620px;
                width: 100%;

                display: flex;
                // align-items: center;
                justify-content: space-between;

                margin-right: 200px;
                
                .header-dropdown {
                    max-width: 220px;
                    width: 100%;
                    display: block;
                            
                    pointer-events: all;

                    transition: 0.2s;

                    .header-dropdown-button {
                        width: 100%;
                        height: 43px;

                        border: 1px solid #E0DEE3;
                        background: #FFF;
                        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);

                        display: flex;
                        align-items: center;
                        justify-content: center;

                        transition: 0.2s;

                        cursor: pointer;
                        position: relative;

                        .header-dropdown-button-inner {
                            width: 85%;

                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            .header-dropdown-button-text {
                                font-size: 12px;

                                color: #1F4546;

                                b {
                                    color: #ffffff;
                                }
                            }

                            img {
                                width: 12px;
                                height: 10px;
                                
                                transition: 0.2s;
                            }
                        }
                    }

                    .header-dropdown-list {
                        position: absolute;
                        z-index: -1;
                        
                        max-width: 220px;
                        width: 100%;

                        // background-color: rgb(255, 255, 255);
                        // border-radius: 15px;

                        border: 1px solid #E0DEE3;
                        background: rgba(0, 0, 0, 0.20);

                        transition: 0.2s ease-out;

                        .header-dropdown-list-title {
                            width: 85%;
                            height: 31px;

                            margin: 0 auto;

                            font-size: 11px;

                            padding-top: 14px;
                            box-sizing: border-box;

                            // color: #000000;
                            color: #FFFFFF;
                        }

                        .header-dropdown-list-element {
                            width: 100%;

                            font-size: 12px;

                            display: flex;
                            align-items: center;
                            justify-content: center;

                            cursor: pointer;
                            transition: 0.2s;

                            // color: #000000;
                            color: #FFFFFF;

                            .header-dropdown-list-element-inner {
                                width: 85%;
                                display: flex;
                                align-items: center;

                                .header-dropdown-list-radio-button {
                                    width: 15px;
                                    height: 15px;

                                    // border: 1px solid rgb(63, 63, 63);
                                    border: 1px solid rgb(255, 255, 255);
                                    border-radius: 100px;
                                    box-sizing: border-box;

                                    margin-right: 12px;

                                    display: flex;
                                    align-items: center;
                                    justify-content: center;

                                    .header-dropdown-list-radio-button-inner {
                                        width: 9px;
                                        height: 9px;

                                        // background-color: #a15652;
                                        background-color: #ffffff;
                                        border-radius: 100px;

                                        transition: 0.2s;
                                    }
                                }

                                .header-dropdown-list-checkbox-button {
                                    width: 16px;
                                    height: 16px;

                                    // border: 1px solid rgb(63, 63, 63);
                                    border: 1px solid rgb(255, 255, 255);
                                    border-radius: 1px;
                                    box-sizing: border-box;

                                    margin-right: 12px;

                                    display: flex;
                                    align-items: center;
                                    justify-content: center;

                                    .header-dropdown-list-checkbox-button-inner {
                                        width: 10px;
                                        height: 10px;

                                        // background-color: #a15652;
                                        background-color: rgba(77, 172, 137, 1);
                                        border-radius: 1px;

                                        transition: 0.2s;
                                    }
                                }
                            }

                            &:last-child {
                                // border-radius: 0 0 15px 15px;
                                // border-radius: 0 0 8px 8px;
                            }
                        }
                    }
                }

                .header-right-switchbox {
                    height: 48px;
                    // width: 100%;

                    display: flex;
                    // align-items: left;
                    flex-direction: column;
                    justify-content: space-between;
                }

                .header-switch {

                    display: flex;
                    align-items: center;
                    justify-content: center;
                            
                    pointer-events: all;

                    height: 24px;

                    .header-switch-inner {
                        width: 100%;

                        display: flex;
                        align-items: center;

                        cursor: pointer;

                        .header-switch-element {
                            margin-right: 15px;
                        }

                        .header-switch-label {
                            font-size: 13px;
                            color: rgba(31, 69, 70, 1);
                        }
                    }
                }

                .header-button {
                    max-width: 210px;
                    width: 100%;
                    height: 41px;

                    backdrop-filter: blur(10px);
                    box-shadow: inset 0 0 10px 5px rgba(255, 255, 255, 0.02);
                    border: 1px solid #e6e2e2e5;
                    border-radius: 8px;

                    transition: 0.2s;

                    cursor: pointer;
                    pointer-events: all;

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    font-size: 12px;
                    color: #FFFFFF;

                    .header-button-inner {
                        max-width: 55%;
                        width: 100%;

                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        img {
                            width: 16px;

                            transition: 0.3s;
                        }
                    }
                }
            }
        }
    }
}