.main {

    @media screen and (max-width: 1300px) {
        .header {
    
            .header-container {
                max-width: 90% !important;
                margin-top: 30px !important;
    
                align-items: baseline !important;
                
                .header-right {
                    display: block !important;
                    max-width: calc(170px + 170 * ((100vw - 320px) / 1600)) !important;

                    margin-right: 0!important;

    
                    .header-dropdown {
                        max-width: calc(170px + 170 * ((100vw - 320px) / 1600)) !important;
    
                        pointer-events: none !important;
    
                        .header-dropdown-button {
                            pointer-events: all !important;
                        }
    
                        .header-dropdown-list {
                            max-width: calc(170px + 170 * ((100vw - 320px) / 1600)) !important;
    
                            pointer-events: all !important;
                        }
                    }

                    .header-button {
                        max-width: calc(170px + 170 * ((100vw - 320px) / 1600)) !important;
                    }
    
                    .header-right-switchbox {
                        position: fixed;
                        left: 5%;
                        top: 100px;
                    
                        max-width: calc(135px + 130 * ((100vw - 320px) / 1600));
                    }

                    .header-switch-element {
                        margin-right: calc(10px + 10 * ((100vw - 320px) / 1600)) !important;
                    }

                    // .header-switch {
                    //     max-width: calc(170px + 170 * ((100vw - 320px) / 1600)) !important;
                    //     height: 43px !important;
    
                    //     bottom: 0 !important;
                    //     position: fixed;
    
                    //     // background-color: #FFFFFF;
                    //     // border-radius: 15px 15px 0 0;
    
                    //     background-color: rgba(197, 197, 197, 0.153);
                    //     backdrop-filter: blur(10px);
                    //     box-shadow: inset 0 0 10px 5px rgba(255, 255, 255, 0.02);
                    //     border: 1px solid #e6e2e2e5;
                    //     border-radius: 8px 8px 0 0;
    
                    //     .header-switch-inner {
                    //         max-width: 90% !important;
    
                    //         .header-switch-label {
                    //             // color: #000000 !important;  
                    //         }
                    //     }
                    // }
                }
            }
        }

        .bottom-tips {
            .bottom-tips-qr {
                left: 5% !important;
                 
                img {
                    width: 75px !important;
                    height: 75px !important;
                }
            }

            .bottom-tips-maptype {
                top: 180px !important;
                left: 5% !important;
            }
        }

        .card {
            .card-container {
                max-height: 96% !important;
                overflow-y: auto;
            }
        }
    }
    
    @media screen and (max-width: 650px) {
    
        .header {
            .header-container {
    
                .header-left {
                    .header-logo {
    
                        .header-logo-top {
                            img {
                                width: calc(120px + 130 * ((100vw - 320px) / 1600)) !important;
                            }
                        }
    
                        .header-logo-bottom {
                            font-size: calc(10px + 10 * ((100vw - 320px) / 1600)) !important;
                        }
                    }
                }
    
                .header-dropdown {
    
                    .header-dropdown-button-inner {
                        .header-dropdown-button-text {
                            font-size: calc(10px + 10 * ((100vw - 320px) / 1600)) !important;
                        }
                    }
    
                    .header-dropdown-list {
    
                        .header-dropdown-list-title {
                            font-size: calc(10px + 10 * ((100vw - 320px) / 1600)) !important;
                        }
    
                        .header-dropdown-list-element {
                            font-size: calc(12px + 12 * ((100vw - 320px) / 1600)) !important;
                        }
    
                    }
    
                }
    
                .header-right-switchbox {
                    top: calc(30px + (65px + 65 * ((100vw - 320px) / 1600))) !important;
                }

                .header-switch-inner {
                    .header-switch-label {
                        font-size: calc(10px + 10 * ((100vw - 320px) / 1600)) !important;
                    }
                }

                .header-button {
                    font-size: calc(10px + 10 * ((100vw - 320px) / 1600)) !important;
                }
    
            }
        }
    
        .bottom-tips {
    
            .bottom-tips-qr {
                img {
                    width: calc(60px + 85 * ((100vw - 320px) / 1600)) !important;
                    height: calc(60px + 85 * ((100vw - 320px) / 1600)) !important;
                }
            }
    
            .bottom-tips-marks {
                display: block !important;
    
                position: absolute;
                left: 20px;
                bottom: calc(30px + (85px + 85 * ((100vw - 320px) / 1600)));
    
                .bottom-tips-mark {
                    margin-bottom: 15px;
    
                    .bottom-tips-mark-label {
                        font-size: calc(12px + 12 * ((100vw - 320px) / 1600)) !important;
                    }
    
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .bottom-tips-maptype {
                top: calc(110px + (65px + 65 * ((100vw - 320px) / 1600))) !important;
                left: 5% !important;
                
                width: calc(60px + 85 * ((100vw - 320px) / 1600)) !important;
                height: calc(60px + 85 * ((100vw - 320px) / 1600)) !important;
            }
        }

        .card {
            justify-content: center;

            .card-container {
                max-width: 92% !important;
                right: auto !important;

                .card-bottom {

                    .card-title {
                        font-size: calc(17px + 17 * ((100vw - 320px) / 1600)) !important;
                    }

                    .card-desc {
                        font-size: calc(12px + 13 * ((100vw - 320px) / 1600)) !important;
                    }

                    .card-bottom-button-inner  {
                        font-size: calc(12px + 13 * ((100vw - 320px) / 1600)) !important;
                    }
                }
            }
        }

        .window-card {
            .window-card-container {
                max-width: 100%;
                max-height: 100%;
            }
        }

        .small-card {
            display: flex;
            align-items: center;
            justify-content: center;


            .small-card-container {
                left: auto !important;
                top: auto !important;

                max-width: 80% !important;
                height: 350px !important;

                display: block !important;

                .small-card-preview {
                    max-width: 100% !important;
                    height: 175px !important;
                }

                .small-card-elements {
                    max-width: 85% !important;
                    
                    margin: 0 auto;
                    margin-top: 15px !important;

                    .small-card-elements-title {
                        margin-bottom: 10px !important;
                    }

                    .small-card-elements-desc {
                        margin-bottom: 15px !important;
                    }
                }
            }
        }
    
        
    }
}

