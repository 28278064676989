@font-face {
    font-family: 'Arabic';
    src: url(./static/fonts/Graphik-Arabic-SemiBold.ttf);
}

@font-face {
    font-family: 'Helvetica';
    src: url(./static/fonts/Helvetica.ttf);
    font-weight: normal;
}

@font-face {
    font-family: 'Helvetica';
    src: url(./static/fonts/Helvetica-Bold.ttf);
    font-weight: 700;
}

@font-face {
    font-family: 'Helvetica';
    src: url(./static/fonts/helvetica-light-587ebe5a59211.ttf);
    font-weight: 300;
}

@font-face {
    font-family: 'Graphik Arabic';
    src: url(./static/fonts/GraphikArabic-Extralight.ttf);
    font-weight: 100;
}

@font-face {
    font-family: 'Graphik Arabic';
    src: url(./static/fonts/GraphikArabic-Semibold.ttf);
    font-weight: 600;
}

.App {
    font-family: Arabic, sans-serif;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}
  
::-webkit-scrollbar-thumb {
    background: #a7a7a7;
    border-radius: 3px;
}